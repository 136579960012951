export const LOADFIREBALLDATA = 'LOADFIREBALLDATA';
export const LOADNEODATA = 'LOADNEODATA';
export const SHOWNEODATA = 'SHOWNEODATA';
export const GETDATE = 'GETDATE';
export const SLIDERCHANGE = 'SLIDERCHANGE';
export const TOGGLEPOPUP = 'TOGGLEPOPUP';
export const LOADANNUALDATA = 'LOADANNUALDATA';
export const TOGGLEFIREBALL = 'TOGGLEFIREBALL';
export const OFFFIREBALL = 'OFFFIREBALL';
export const TOGGLEGRAPH = 'TOGGLEGRAPH';
export const SINGLENEODATA = 'SINGLENEODATA';
export const SHOWPOPUP = 'SHOWPOPUP';
export const CLOSEPOPUP = 'CLOSEPOPUP';
export const SHOWNEOPOPUP = 'SHOWNEOPOPUP';
export const SHOWHEATMAP = 'SHOWHEATMAP';
export const TOGGLEDANGERBAR = 'TOGGLEDANGERBAR';